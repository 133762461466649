// Import de bootstrap depuis les node modules 
@import '~bootstrap/scss/bootstrap';

// Import des fichiers Font Awesome depuis les node modules ( All icon minifiés )
// 31/03/23 -> Retrait du fichier car trop lourd ! Ajout du CDN Font Awesome
//@import '~@fortawesome/fontawesome-pro/css/all.min.css';

// Import de la font Nunito depuis les node modules
@import '~@fontsource/nunito';



/* Style globaux */
/* Font */
main {
	margin-bottom: 5rem;
}

body {
	font-family: 'Nunito', sans-serif;
	background-color: var(--lg-1) !important;
	color: var(--dg-3);
}

hr {
    border-radius: 50%;
}


.hero {
	&-title {
		font-weight: 700;
	}
	&-p {
		font-size: 1.2em;
		color: var(--dg-2);
	}
}



.w-shadow-pdf {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.large-illustration {
	border-radius: 2rem;
	width: 100%;
	min-height: 20rem;
	max-height: 22rem;
	object-fit: cover;

	@media screen and (min-width: 48em) {
		min-height: 20rem;
		max-height: 32rem;
	}
}

.triptyque-illustration {
	border-radius: 2rem;
	width: 100%;
	min-height: 30rem;
	max-height: 32rem;
	object-fit: cover;
	margin: 1rem 0rem;

	@media screen and (min-width: 48em) {
		min-height: 30rem;
		max-height: 42rem;
	}
}

.square-illustration {
	border-radius: 2rem;
	width: 100%;
	min-height: 20rem;
	max-height: 22rem;
	object-fit: cover;
	margin: 1rem 0rem;

	@media screen and (min-width: 48em) {
		min-height: 20rem;
		max-height: 22rem;
	}
}



.home-logo {
	max-width: 100%;
}

.cursor {
	cursor: pointer;
}




/*********

**********/
// .fade {
// 	transition: .6s;
// 	animation: fadeIn .6s;
// }
.search-result {
	transition: .6s;
	animation: fadeIn .6s;

	&:hover {
		cursor: pointer;
		transform: scale(1.01) translateY(-.1em);
		box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	}
}
@keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}


.giftcard-result {
	transition: .4s;
	animation: fadeIn .6s;
	border-radius: 1.2rem;

	&:hover {
		cursor: pointer;
		background-color: var(--blue-1);
		color: white;
		transform: scale(1.01);
	}
}

/**********
    NAV 
**********/

/* Primary Nav */
.navbar {
    background-color: var(--green-2);

	.logo-navbar {
		width: 40px;
	}

	.nav-link {
		color: white !important;
		opacity: .7;

		&:hover {
			color:white !important;
			opacity: 1;
		}
	}

	.dropdown-menu {
		border: none;
		background-color: white;
		padding: 1rem;
		z-index: 10000;
		border-radius: 1.2rem;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

		.dropdown-item {
			padding: .5rem 1.5rem;
			margin: .5rem 0;
			color: var(--dg-2);
			border-radius: .8rem;
			transition: .6s;

			&:hover,
			&:focus {
				color:var(--green-2);
				background-color: var(--lg-1);
				transform: scale(1.02) translateY(-.1em);
			}

			&.cta {
				//color:var(--green-2);
				background-color: var(--lg-1);

				&:hover,
				&:focus {
					transform: scale(1.02) translateY(-.1em);
				}
			}
		}
	}
	
}


/* Secondary Nav - SASS OK */
.secondary-nav {
    overflow-x: scroll;
	z-index: -1;
	align-items: baseline;

	.nav-link {
		display: flex;
		transition: .4s;
		border-radius: 1.2em;
		color: var(--dg-2);
		white-space: nowrap;
		align-items: center;
		padding: .5rem 1rem;
		border: .1rem solid var(--lg-3);
		margin: .5rem .2rem;

		&:hover,
		&:focus {
			color: var(--green-2);
		}
	}

	.nav-link i {
		margin-right: .5em;
	}

	@media screen and (min-width: 48em) {

		.nav-link {
			white-space: normal;
			flex-direction: column;
			justify-content: center;
			min-width: 8.5rem;
			max-width: 10.5rem;
			padding: .5rem;
			margin-top: 1rem;
			border: none;
		}
		.nav-link i {
			align-self: center;
			margin-right: 0;
			margin-bottom: .5rem;
		}
	}

	.active {
		color: var(--green-1) !important;
	}

	.disabled {
		color: var(--dg-1);
	}

}

.breadcrumb {
	overflow-x: scroll !important;

	li {
		white-space: nowrap;
	}
}

/* Style de la pagination KNP */
.navigation {

	.pagination {
		margin: 1rem 0;
		display: flex;
		align-items: center;

		.current {
			border-radius: .5rem;
			background-color: var(--green-2);
			transform: scale(1.02);
			padding: .6rem 1rem;
			color: white;
			font-weight: 600;
			margin: .5rem;
		}

		span a {
			border-radius: .5rem;
			background-color: transparent;
			margin: .5rem;
			padding: .6rem 1rem;
			color: var(--green-1);
			font-weight: 600;
			text-decoration: none;
			transition: .4s;

			&:hover,
			&:focus {
				background-color: var(--green-3);
				color: white;
				transform: scale(1.02);
			}
		}

	}	
}



/* Gift Actions */
.gift-actions {
	overflow-x: auto;
	
	.nav-item {
		background-color: transparent !important;
		min-width: 7rem;
		max-width: 7rem;
	}

	.nav-link {
		transition: .4s;
		color: var(--dg-2);

		
		&:hover,
		&:focus {
			color: var(--green-2);
		}
	}

	.active {
		background-color: transparent !important;
		color: var(--dg-2) !important;
	}

	.disabled {
		color: var(--dg-1);
	}
}

/*********
ASIDE MENU
**********/
.aside-menu {
	display: flex;
	height: 100%;
	display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
    z-index: 20;

	.nav-link {
		transition: .4s;
		color: var(--dg-2);

		&:hover,
		&:focus {
			color: var(--green-2);
		}
	}

	.active {
		background-color: var(--green-2) !important;
		color: white !important;
	}

	.disabled {
		color: var(--dg-1);
	}
}


/**********
    CARD 
**********/
.card {
	border-radius:1rem;
	border: none;
	transition: .4s;

	&-img-top {
		object-fit: cover;
		/* border-radius: 1.2em 1.2em 0 0 !important; */
		height: 17em;
		border-radius: 1rem;
	}

	&-footer {
		border-bottom-left-radius: 2rem !important; 
		border-bottom-right-radius: 2rem !important;
	}
}
@media screen and (min-width: 960px) {
	.card {
		border-radius:2rem;
	}
}

.img-cover {
	border-radius: .8rem;
}
@media screen and (min-width: 960px) {
	.img-cover {
		border-radius:1.2rem;
	}
}



.prestation {
    transition: .6s;

	img {
		transition: .6s;
	}

	&:hover,
	&:focus {
		transform: scale(1.02) translateY(-.2rem);
    	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

		img {
			transform: scale(1.02) translateY(-.2rem);
		}
	}

}


.text-img-left-right {

	@media screen and (min-width: 1200px) {
		:nth-child(even) {
			.first-col { 
				order: 2;
			}
			.second-col { 
				order: 1;
			}
		}
	}
	
	.first-col { 
		order: 1;
	}
	.second-col { 
		order: 2; 
	}
}



/**********
    FORM 
**********/
.form-control,
.form-select {
    border-radius: 0.5rem !important;
}



/**********
    BTN 
**********/
.btn {
    border-radius: .8em;
    padding: .6em 1.5em;
	transition: .6s;
    border: 0;
    color: white;
}





/* COMPOSANTS */
.mini-avatar {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	object-fit: cover;
}

.mini-prestation-picture {
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	object-fit: cover;
}

.search-img {
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	object-fit: cover;
}


/* MINI CART */
.mini-cart {
	img {	
		border-radius: 1em;
		width: 5em;
		height: 5em;
		object-fit: cover;	
	}
}


/* GRID LAYOUT */
.grid-layout {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-columns: repeat(3, 1fr);
	gap: 1.5em;

	#card-1 {
		grid-column: 1 / 3;
	}

	#card-2 {
		grid-column: 3 / 5;
		grid-row: 1 / 4;
	}

	#card-3 {
		grid-column: 1 / 2;
		grid-row: 2 / 4;

		.card-img-top {
			object-fit: cover;
			border-radius: 1.5em 1.5em 0 0 !important;
			height: 17em;
		}
	}

	#card-4 {
		grid-column: 2 / 3;
		grid-row: 2 / 4;
	}

	img {
		object-fit: cover;
		height: 100%;
	}

}


/*******************************
    IMAGES
********************************/

/* Home et Profile */
.team-profil-picture {
	border-radius: 50%;
	object-fit: cover;
	width: 5em;
	height: 5em;

	@media screen and (min-width: 768px) {
        width: 6em;
		height: 6em;
    }

	@media screen and (min-width: 1140) {
        width: 8em;
		height: 8em;
    }

}

.icone-picture {
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	object-fit: cover;
	border: 2px solid white;
}

/* Pdf pages */
.pdf {
	.img-prestation {
		border-radius: 50%;
		height: 15em;
		width: 15em;
		object-fit: cover;
	}
}


footer {
	min-width: 100%;

	.footer-link {
		display: block;
		color: var(--lg-1);
		text-decoration: none;
		transition: .6s;
		font-size: .9rem;

		&:hover,
		&:focus {
			color:var(--green-1) ;
			text-decoration: underline;
		}
	}
}


.login-background {
	background: url('../img/login/flat-green-valley-237380290.svg');
	background-position: center;
	background-size: cover;
	min-height: 80vh;

	body {
		background-color: none;
	}

	main {
		margin-bottom: 0;
	}
}

