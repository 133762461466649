
.fc-scrollgrid-sync-inner {
  display: flex;
}
.color-employee {
  width: .3rem;
  height: 1.5rem;
  border-radius: .5rem;
}
.profile-picture {
  width: 1.4rem;
  height: 1.4rem;
}
